import request from '../utils/request';

/**
 * 获取课程表
 * @param phone
 * @returns {AxiosPromise}
 */
export const getCourseScheduleList = (query) => {
    return request({
        url: '/sys/courseSchedule/page',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//导出约课课程表
export function exportExcelcourseSchedule(query) {
    return request({
        url: '/sys/courseSchedule/excel',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}
/**
 * 根据课表id 获取课表约课学员详细信息
 * @param query
 * @returns {AxiosPromise}
 */
export const getAboutCourseStudents = (courseScheduleId) => {
    return request({
        url: '/sys/courseSchedule/getList',
        method: 'get',
        params: {
            courseScheduleId
        }
    });
}
//展开获取学生详情
export const getStudent = (courseScheduleId) => {
    return request({
        url: '/sys/courseSchedule/getList',
        method: 'get',
        params: {courseScheduleId}
    });
}