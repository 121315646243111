<template>
<div style="padding: 20px">
<!--  搜索模块-->
  <div>
    <select-area size="small" @areaListId="getAreaListId" />
        <el-input
            size="small"
            v-model="query.studentName"
            placeholder="学员姓名"
            style="width: 160px"
            class="mr10"
        ></el-input>
        <el-input
            size="small"
            v-model="query.coachName"
            placeholder="上课老师"
            style="width: 160px"
            class="mr10"
        ></el-input>
    <el-input
        size="small"
        v-model="query.coachName"
        placeholder="发布老师"
        style="width: 160px"
        class="mr10"
    ></el-input>
    <el-select
        size="small"
        v-model="query.state"
        style="width: 130px"
        placeholder="订单状态"
        class="mr10"
    >
      <el-option label="待开课" :value="1"></el-option>
      <el-option label="开课中" :value="2"></el-option>
      <el-option label="已结课" :value="2"></el-option>
    </el-select>
    <el-date-picker
        size="small"
        style="width: 260px"
        v-model="courseDate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
    >
    </el-date-picker>
    <el-button
        type="primary"
        size="small"
        icon="Search"
        style="margin-left: 10px"
        @click="queryData">搜索
    </el-button>
    <el-popconfirm @confirm="exportExcel" title="确定要导出吗？">
          <template #reference>
            <el-button type="primary" size="small"
              >导出</el-button
            >
          </template>
        </el-popconfirm>
  </div>

  <el-table
      v-loading="courseScheduleLoading"
      :data="courseScheduleList"
      border class="table"
      ref="multipleTable"
      :row-style="{ height: 0 + 'px' }"
      :cell-style="{ padding: 7 + 'px' }"

      header-cell-class-name="table-header"
       @selection-change="handleSelectionChange"

  >
    <el-table-column
        prop="courseScheduleId"
        label="id"
        width="80"
    >
    </el-table-column>
    <el-table-column
        show-overflow-tooltip="true"
        prop="createName"
        label="发布者"
        width="100"
    >
    </el-table-column>
    <el-table-column
        show-overflow-tooltip="true"
        prop="date"
        align="center"
        label="上课时间"
        width="240"
    >
      <template #default="scope">
        {{ scope.row.date }}  {{ scope.row.startTime }} ~ {{ scope.row.endTime }}
      </template>
    </el-table-column>
    <el-table-column
        show-overflow-tooltip="true"
        prop="campusName"
        label="上课校区"
        width="200"
    >
    </el-table-column>
    <el-table-column
        show-overflow-tooltip="true"
        prop="coachName"
        label="上课老师"
        width="100"
    >
    </el-table-column>
    <el-table-column
        show-overflow-tooltip="true"
        prop="typeLabel"
        label="课程类型"
        width="100"
    >
    </el-table-column>
      <el-table-column
          label="当前数量"
          width="100">
        <template #default="scope">
            <el-tag style="width: 60px" @click="getAboutCourseStudents(scope.row.courseScheduleId)">
              {{scope.row.newNumber == null ?  '0 位' : scope.row.newNumber}}/{{scope.row.maxNumber}}
            </el-tag>
        </template>
    </el-table-column>
    <el-table-column
        show-overflow-tooltip="true"
        label="课程状态"
        width="100"
    >
      <template #default="scope">
        <el-tag
            :type="scope.row.state == '1'
            ? 'success':scope.row.state == '2'
            ? 'info': 'warning'">
          {{scope.row.state == '1' ? '待开课': scope.row.state == '2' ? '开课中' : scope.row.state == '3' ? '已结课' : '未知状态'}}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
        show-overflow-tooltip="true"
        prop="openDate"
        label="可见日期"
        width="180"
    >
    </el-table-column>
    <el-table-column
        show-overflow-tooltip="true"
        prop="createTime"
        label="创建时间"
        width="180"
    >
    </el-table-column>

    <el-table-column label="操作">
      <template #default="scope">
        <el-icon><EditPen /></el-icon>
        <el-button
            type="text"

            @click="handleEdit(scope.$index, scope.row)"
        >编辑
        </el-button>
        <el-button
            type="text"

            style="color:red;"
            @click="teachers(scope.$index, scope.row)"
        >删除
        </el-button>

      </template>
    </el-table-column>
  </el-table>
  <div class="pagination">
    <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="query.pageIndex"
        :page-size="query.pageSize"
        :total="pageTotal"
        @current-change="handlePageChange"
    ></el-pagination>
  </div>
<!--  查看约课学员详细信息-->
  <el-dialog v-model="courseScheduleStudentsModal" title="约课学员" class="dialog">
    <template #default>
      <el-table
          v-loading="courseScheduleLoading"
          :data="courseScheduleStudentsList"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column
            show-overflow-tooltip="true"
            prop="id"
            label="id"
            width="100">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip="true"
            prop="studentName"
            label="学员姓名"
            width="140">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip="true"
            prop="cardName"
            label="卡片"
            width="140">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip="true"
            prop="createTime"
            label="创建日期"
            width="200">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip="true"
            prop="orderType"
            label="卡片类型"
            width="200">
            <template #default="scope">
          <el-tag>
          {{scope.row.orderType == '1' ? '主卡':'副卡'}}
          </el-tag>
          </template>
        
          </el-table-column>
      </el-table>
    </template>
    <template #footer>
    </template>
  </el-dialog>
</div>

</template>
<script>
import selectArea from "../../components/SelectArea";
import { getCourseScheduleList,getAboutCourseStudents,exportExcelcourseSchedule } from "@/api/courseSchedule";
export default {
  name: "CourseSchedule",
  components: {
    selectArea
  },
  data() {
    return {
      courseDate:"",
      courseScheduleList:[],
      courseScheduleLoading:false,
      courseScheduleStudentsList:[],
      courseScheduleStudentsModal:false,
      pageTotal:0,
      query:{
        pageIndex:0,
        pageSize:10,
        studentName:"",
        coachName:"",
        createName:"",
        startDate:"",
        areaList:[],
        endDate:"",
        state:""
      }
    }
  },
  created() {
    this.getData();
  },
  methods:{
    //导出课程表
    
    exportExcel(){ 
      exportExcelcourseSchedule(this.query)
    },
    getAreaListId(val) {
      this.query.areaList = val;
    },
    queryData(){
      this.query.pageIndex = 0;
      this.pageTotal = 0;
      this.getData();
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
    getData(){
      getCourseScheduleList(this.query).then((res) => {
        if (res.code == 200) {
          this.courseScheduleList = res.data.records;
          this.pageTotal = res.data.total
        } else {
          this.$message.error("获取数据失败");
        }
      });
    },
    getAboutCourseStudents(id){
      this.courseScheduleStudentsModal = true;
      getAboutCourseStudents(id).then((res) => {
        if (res.code == 200) {
          this.courseScheduleStudentsList = res.data;
        } else {
          this.$message.error("获取数据失败");
        }
      });
    }
  }
}
</script>

<style scoped>
.mr10 {
  margin-right: 10px;
}
.table {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
}
div /deep/ .el-dialog__body{ 
  padding: 0 0 0 10px !important;
}
.course{
  text-align: left;
  margin: 10px;
  box-shadow: 0 0 10px rgba(59, 59, 59, 0.1);
  padding: 16px 30px;
  border-radius: 16px;
  width: 200px;
}
</style>